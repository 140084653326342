$(function(){
	$('.main-nav > .menu-item-has-children > a, .main-nav > .menu-item-has-children > span').on('click', function(){
		if( $(this).parent().hasClass('open') ) {
			$(this).parent().find('.sub-menu').hide();
			$(this).parent().removeClass('open');
		} else {
			$('.main-nav li').find('.sub-menu').hide();
			$('.main-nav li').removeClass('open');

			$(this).parent().find('.sub-menu').show();
			$(this).parent().toggleClass('open');
		}

		var height = $('.open ul').height() + 48;
		$('main').css('paddingTop', height);
	});

	$(window).resize(function(){
		if ($('.open ul').height() != null && $('.open ul').height() > 0) {
			var height = $('.open ul').height() + 48;
			$('main').css('paddingTop', height);
		}
	});

	$('[name="s"]').keyup(function(){
		if( $('[name="s"]').val().length >= 1 ) {
			$(this).parent().find('button').addClass('text');
		} else {
			$(this).parent().find('button').removeClass('text');
		}
	});

	$('.search-icon').on('click', function(){
		$(this).toggleClass('open');
	});


	$('.toggle').on('click', function(){
		$('nav, .supporting-nav').addClass('show');
		$('.overlay').addClass('show');
		$('body').addClass('prevent');
	});

	$('nav span').on('click', function(){
		if( $('nav > ul ul').height > 0 ) {
			$('nav > ul ul').slideUp();
		} else {
			$(this).find('ul').slideToggle();
		}

	});

	$('.overlay').on('click', function(){
		$(this).removeClass('show');
		$('nav, .supporting-nav').removeClass('show');
		$('body').removeClass('prevent');
	});

});



///////


var ctaSidebar = $('article');
ctaSidebar.data( 'position', ctaSidebar.position() );

function getScroll() {
	var b = document.body;
	var e = document.documentElement;
	return {
		left: parseFloat( window.pageXOffset || b.scrollLeft || e.scrollLeft ),
		top: parseFloat( window.pageYOffset || b.scrollTop || e.scrollTop ),
	};
}

$(window).scroll(function(){
	ctaSidebar.data( 'position', ctaSidebar.position() );

	var shPos = ctaSidebar.data('position'), scroll = getScroll();
	if ( shPos.top < scroll.top ){
		$('.sidebar').addClass('fixed');
	}
	else {
		$('.sidebar').removeClass('fixed');
	}

});

// wraps scrollable tables on medium and smaller viewports
function scrollWrap() {
	$('table').wrap('<div class="scroll-wrapper"></div>');
	$('.scroll-wrapper').before( '<div class="scroll-header"> Swipe to view more content</div>' );
}
scrollWrap();

// show or hide table scroll header based on if overflowing its parent
function handleScrollResize() {
	$('table').each(function(index) {
		var table = $(this);
		var tableWidth = table.outerWidth();
		var parentWidth = table.parent().outerWidth();
		var scrollHeader = table.parent().prevAll('.scroll-header:first');

		if (tableWidth > parentWidth) {
			// element has overflow
			scrollHeader.show();
		} else {
			// element doesn't overflow
			scrollHeader.hide();
		}
	})
}

$(window).on("resize", function () {
	handleScrollResize();
});
$(document).ready( function () {
	handleScrollResize();
});